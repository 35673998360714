import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext';  
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import UsePost from '../../../components/hook/UsePost'; 
import './streaming.css'
import CountDown from '../../../components/interface/count-down/CountDown'; 
import Player from './Player'

const LiveStreaming = props => { 
    const { handleID, handleApiURL, handleLoading, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
    console.log(data?.title?.rendered)

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/multimedia' handleLoading={handleLoading} handleID={handleID} id='1795'>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/streaming' url_es='/es/streaming' url_pt_br='/pt-br/streaming' {...props} />
                <Main className='container streaming' loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider/>
                            <StickyHeader/>   
                            
                             <Player title={data?.title?.rendered} content={data?.content?.rendered}  />
                            <CountDown />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default LiveStreaming
