// config-page.js
const settings = {
    // IDs de páginas 
    home_page_id : "3835",
    participate_page_id: "3842",
    ftl_page_id : "2711",
    becas_asociados_page_id: "4103",
    becas_page_id: "3890",
    about_page_id : "699",
    sponsorship_page_id: "1286",
    gral_information_page_id: "3901",
    travel_discounts_page_id: "2797",
    hotels_page_id: "3898",
    visas_page_id: "3941",
    fees_page_id: "4048",
    preparatory_page_id: "2811",
    networking_page_id: "2488",
    transport_page_id: "3233"
};
  
export default settings;
  