import React, { useState, useContext, useEffect } from 'react'
import { SessionContext, LangContext } from '../../../../context/DataContext'
import Main from '../../../../components/interface/main/Main'
import Footer from '../../../../components/navegation/footer/Footer'
import Header from '../../../../components/navegation/header/Header'
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement'
import UseTimeOut from '../../../../components/hook/UseTimeOut'
import UseLangLocation from '../../../../components/hook/UseLangLocation'
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'
import Videos from '../agenda-sessions/presentations-content/presentation-modal/Videos'
import Slides from '../agenda-sessions/presentations-content/presentation-modal/Slides'
import UseLang from '../../../../components/hook/UseLang'
import Speakers from '../agenda-sessions/presentations-content/presentation-modal/Speakers'

const PresentationList = props => {
    const { myEventAgenda } = useContext(SessionContext);
    const {myLang} = useContext(LangContext)
    const [loading, setLoading] = useState(true);
    const status = 'publish'
    const title = 'PresentationList'

    const myTitle = myLang === 'es' ? 'Presentaciones y videos' : '' || myLang === 'en' ? 'Presentations and videos' : '' || myLang === 'pt-br' ? 'Apresentações e vídeos' : ''


    useEffect(() => {
        const documentTitle = document.title;
        document.title = `LACNIC 41 | ${myTitle}`;
        return () => {
          document.title = documentTitle;
        };
      }, [myTitle]);
     
    // Ordenar todos los eventos por hora de inicio
    myEventAgenda.sort((a, b) => {
        const startTimeA = new Date(a.acf.start_time).getTime();
        const startTimeB = new Date(b.acf.start_time).getTime();
        return startTimeA - startTimeB;
    });

    // Agrupar los eventos por sesión
    const groupedBySession = {};
    myEventAgenda.forEach(event => {
        const session = event?.acf?.sessions?.[0]?.name;
        if (session) {
            if (!groupedBySession[session]) {
                groupedBySession[session] = [];
            }
            groupedBySession[session].push(event);
        }
    });

    // Ordenar los eventos dentro de cada grupo por fecha
    for (const session in groupedBySession) {
        groupedBySession[session].sort((a, b) => {
            const dateA = new Date(a.acf.start_time);
            const dateB = new Date(b.acf.start_time);
            return dateA - dateB;
        });
    }

    // Unir los eventos nuevamente en un solo array, manteniendo el orden de las sesiones
    const presentationsData = Object.values(groupedBySession).flat();
 
    return (
        <UseLangLocation>
            <Header title={title} loading={loading} url_es='/es/programa/presentaciones-y-videos' url_en='/en/programme/presentations-and-videos' url_pt_br='/pt-br/programa/apresentacoes-e-videos' {...props} />
            <Main className='container schedule_table' status={status} loading={loading}  >
                <section>
                    <UseTimeOut timeout='1000' loading={loading} setLoading={setLoading} title={title}>
                        <SponsorsSlider />
                        <article><div>
                            <h1>
                                <UseLang es>Presentaciones y videos</UseLang>
                                <UseLang en>Presentations and videos</UseLang>
                                <UseLang pt_br>Apresentações e vídeos</UseLang>
                            </h1>
                            <figure className='presentationsList'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <th><UseLang es>Sesión </UseLang>  <UseLang en>Session</UseLang> <UseLang pt_br>Sessão</UseLang> </th>
                                            <th><UseLang es>Título</UseLang> <UseLang en>Title</UseLang> <UseLang pt_br>Título</UseLang> </th> 
                                            <th><UseLang es>Orador </UseLang>  <UseLang en>Speaker</UseLang> <UseLang pt_br>Palestrante</UseLang> </th>
                                            <th>Video  </th>
                                            <th><UseLang es>Presentación </UseLang>  <UseLang en>Presentation</UseLang> <UseLang pt_br>Apresentação</UseLang> </th>


                                        </tr>
                                        {myEventAgenda ?
                                            presentationsData?.map((item) => {
                                                const { id, acf } = item;
                                                return (
                                                    <React.Fragment key={id}>
                                                    
                                                        {acf?.presentation && acf?.videos && acf?.videos_en && acf?.videos_pt_br && (
                                                            <tr>
                                                                <td>{Array.isArray(acf.sessions) && acf.sessions.map((session) => (
                                                                    <span key={session.id}>{session.name}</span>
                                                                ))}</td>
                                                                <td><UseLang es>{acf?.info_es.title}</UseLang><UseLang en>{acf?.info_en.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br.title}</UseLang> </td> 
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td><Videos videos_es={acf?.videos} videos_en={acf?.videos_en} videos_pt_br={acf?.videos_pt_br} /></td>
                                                                <td><Slides presentation={acf?.presentation} title_status={false} /></td>
                                                            </tr>
                                                        )}
                                                        {!acf?.presentation && acf?.videos && acf?.videos_en && acf?.videos_pt_br && (
                                                            <tr>
                                                                <td>{Array.isArray(acf.sessions) && acf.sessions.map((session) => (
                                                                    <span key={session.id}>{session.name}</span>
                                                                ))}</td>
                                                                <td><UseLang es>{acf?.info_es.title}</UseLang><UseLang en>{acf?.info_en.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br.title}</UseLang> </td>
                                                                 
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td>
                                                                    <Videos videos_es={acf?.videos} videos_en={acf?.videos_en} videos_pt_br={acf?.videos_pt_br} />
                                                                </td>
                                                                <td></td></tr>
                                                        )}
                                                        {acf?.presentation && !acf?.videos && !acf?.videos_en && !acf?.videos_pt_br && (
                                                            <tr>
                                                                <td>{Array.isArray(acf.sessions) && acf.sessions.map((session) => (
                                                                        <span key={session.id}>{session.name}</span>
                                                                    ))}</td>
                                                                <td><UseLang es>{acf?.info_es.title}</UseLang><UseLang en>{acf?.info_en.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br.title}</UseLang> </td>
                                                                 
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td></td>
                                                                <td><Slides presentation={acf?.presentation} title_status={false} /></td></tr>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            })
                                            : undefined}
                                    </tbody>
                                </table>
                            </figure>
                        </div></article>
                    </UseTimeOut>
                </section>
            </Main>
            <OrganizersElement />
            <Footer className="master_foot foot_home" />
        </UseLangLocation>
    )
}

export default PresentationList
