import React, { useState, useContext, useEffect } from 'react';
import { parse, isSameDay } from 'date-fns';
import PresentationItem from './PresentationItem';
import PresentationModal from './PresentationModal';
import { useNavigate } from 'react-router-dom';
import { LangContext } from '../../../../../../context/DataContext';
import slugify from 'react-slugify';
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationRow = (props) => {
  const { agendaData } = props;

  const { myLang } = useContext(LangContext);
  const navigate = useNavigate()
  const [presentationData, setPresentationData] = useState(false);

  useEffect(() => {
    return () => {
      setShowData(false); // Limpiar el estado al desmontar el componente
    };
  }, []);

  const handlePresentationData = (id, name) => {
    setPresentationData(id);
    openModalData(true);
    // Construir la URL base
    const currentPath = window.location.pathname;
    let baseURL = currentPath;
    const params = new URLSearchParams(window.location.search);
    const dayParam = params.get('day');


    const presentationUrl = (url) => {
      // Agregar el parámetro de día si está presente
      if (dayParam) {
        baseURL += `?day=${encodeURIComponent(dayParam).replace(/%2F/g, "/")}&name=${encodeURIComponent(url).replace(/%2F/g, "/")}`;
      }
      // Agregar el parámetro de presentación si es válido
      else {
        baseURL += `?name=${encodeURIComponent(url)}`;
      }

    }

    const myUrlParam = (url) => {
      presentationUrl(url);
      setMyParam(url);
    }

    if (myLang === 'es') {
      myUrlParam(slugify(name?.info_es?.title));
    }
    if (myLang === 'en') {
      myUrlParam(slugify(name?.info_en?.title))
    }
    if (myLang === 'pt-br') {
      myUrlParam(slugify(name?.info_pt_br?.title))
    }

    navigate(baseURL);
  };

  const [showData, setShowData] = useState(false);
  const openModalData = () => setShowData(true);
  const closeModalData = () => {
    // Cerrar la ventana modal al retroceder en la historia del navegador
    setShowData(false);

    // Crear una nueva instancia de URLSearchParams con la URL actual
    const params = new URLSearchParams(window.location.search);

    // Eliminar el parámetro 'name' de los parámetros de la URL
    params.delete("name");

    // Reemplazar los caracteres "%2F" por "/" en el valor del parámetro 'day'
    if (params.get("day")) {
      const dayParam = params.get("day")
      params.set("day", dayParam);
      // Actualizar la URL con los parámetros modificados
      window.history.replaceState(null, "", "?" + params.toString().replace(/%2F/g, "/"));
    }
    else { (window.history.replaceState(null, null, window.location.href.split("?")[0])) }
  };

  // Obtener la fecha seleccionada de la URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedDateStr = urlSearchParams.get('day');

  // Filtrar las presentaciones según la fecha seleccionada
  const filteredAgendaData = agendaData.filter((item) => {
    const startTime = parse(item?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date());

    if (selectedDateStr) {
      const selectedDate = parse(selectedDateStr, 'dd/MM/yyyy', new Date());
      return isSameDay(startTime, selectedDate);
    } else {
      return true;
    }
  });

  const [myParam, setMyParam] = useState()
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const presentationParam = params.get('name');
    setMyParam(presentationParam)

    if (presentationParam) {
      // Si el parámetro 'presentation' está presente en la URL, establecemos el estado presentationData y mostramos la ventana modal
      setPresentationData(presentationParam);
      setShowData(true);
    }
  }, []); // Este efecto se ejecutará solo una vez al cargar el componente

  useEffect(() => {
    const handlePopState = () => {
      setShowData(false); // Cerrar la ventana modal al retroceder en la historia del navegador
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const [titlesExist, setTitlesExist] = useState(true); 
  useEffect(() => {
    const checkTitlesExist = () => {
      let exist = true;
      agendaData.forEach(item => {
        const lang = myLang.toLowerCase();
        if (!item?.acf?.[`info_${lang === 'pt-br' ? 'pt_br' : lang}`]?.title) {
          exist = false;
        }
      });
      setTitlesExist(exist);
    };

    checkTitlesExist();
  }, [agendaData, myLang]); 

  return (
    <article className='presentations'>
      <div className='presentations_list'>

        {filteredAgendaData && titlesExist ?
          <>
            {filteredAgendaData
              .sort((a, b) =>
                parse(a?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date()) -
                parse(b?.acf?.start_time, 'dd/MM/yyyy HH:mm', new Date())
              )
              ?.map((item, id) => (
                <PresentationItem
                  key={id}
                  onClick={() => handlePresentationData(item?.id, item?.acf)}
                  item={item?.acf}
                  presentation_id={item?.id}
                  presentationData={presentationData}
                  closeModalData={closeModalData}
                  show={showData}
                />

              ))}
          </>
          : <>
            <div className="message-agenda-working">
              <UseLang es>Nuestro equipo se encuentra elaborando la agenda del evento, pronto compartiremos más detalles de esta y otras actividades.</UseLang>
              <UseLang en>Our team is currently working on the event agenda. Soon, we will share more details about this and other activities.</UseLang>
              <UseLang pt_br>Nossa equipe está atualmente trabalhando na agenda do evento. Em breve, compartilharemos mais detalhes sobre esta e outras atividades.</UseLang>
            </div> 
          </>
        }
        {agendaData ? (
          <>
            {agendaData.map((item, id) => {
              const titleEs = slugify(item?.acf?.info_es?.title);
              const titlePtBr = slugify(item?.acf?.info_pt_br?.title);
              const titleEn = slugify(item?.acf?.info_en?.title);

              if (myParam === titleEs || myParam === titlePtBr || myParam === titleEn) {
                return (
                  <PresentationModal
                    key={id}
                    show={showData}
                    onCancel={closeModalData}
                    openModalData={openModalData}
                    setPresentationData={setPresentationData}
                    item={item?.acf}
                    presentation_id={item?.id}
                  />
                );
              }
              return null;


            })}


          </>
        ) : undefined}



      </div>
    </article>
  );
};

export default PresentationRow;
