import React, { useState } from 'react';
import AccordionItem from '../../../components/interface/accordion/AccordionItem';
import AccordionWrapper from '../../../components/interface/accordion/AccordionWrapper';

const FtlAccordion = props => {
    const { accordion } = props;
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <>{accordion ?<article className='accordion_ftl'>
            <div>
                <AccordionWrapper>
                    {accordion.map((accordion, index) => {
                        return (
                            <AccordionItem
                                key={index}
                                active={active}
                                handleToggle={handleToggle}
                                label={accordion.label}
                                id={accordion.id}
                                content={accordion.content}
                            />
                        )
                    })
                    }
                </AccordionWrapper>
            </div>
        </article> : null} 
        </>
    )
}

export default FtlAccordion
