import React, { useContext, useState } from 'react';
import MediaScreenBars from './MediaScreenBars';
import MediaScreenMenuBox from './MediaScreenMenuBox';
import './media-screen-menu.css';
//import ItemsMenuMedia from './ItemsMenuMedia'; 
import useMenuContext from '../../../hook/UseMenu';
import { Link, NavLink } from 'react-router-dom';
import { LangContext } from '../../../../context/DataContext';
import slugify from 'react-slugify';

const MediaScreenMenu = props => {

    const { myLang } = useContext(LangContext)
    const { isMenu, loading } = useMenuContext();

    const [show, setShow] = useState(false)

    const handleMenu = () => {
        setShow(!show)
    }

    return (
        <>
            <MediaScreenMenuBox show={show} onCancel={() => handleMenu(show)}>
                <nav className='navMediaScreen'>
                    <button className='close_media_screen_menu' onClick={() => handleMenu(show)}>
                        <i className="icon-xmark" aria-hidden="true"></i>
                    </button>
                    {!loading && isMenu?.items && Array.isArray(isMenu.items) ? (
                        isMenu.items.map((myItem) => {
                            return (
                                <ul key={myItem.ID}>
                                    <li className='media_menu_items' >
                                        <button className='menu_item'>{myItem.title}</button>
                                        <ul className={`mainNavMenu_dropdownBox_link`}>
                                            {myItem.child_items && Array.isArray(myItem.child_items) ? (
                                                myItem.child_items.map((childItem) => (
                                                    <React.Fragment key={childItem.ID}>
                                                        {/* Enlaces internos */}
                                                        {childItem.target === 'self' &&
                                                            <li>
                                                                <NavLink className='mainNavMenu_dropdownBox_link' to={`/${myLang}/${slugify(myItem.title)}/${childItem?.acf?.slug}`}>{childItem.title}</NavLink>
                                                            </li>}
                                                        {/* Enlaces externos */}
                                                        {childItem.target === 'blank' &&
                                                            <li>
                                                                <a className='mainNavMenu_dropdownBox_link' href={`${childItem?.acf?.url}`} target='_blank' rel="noreferrer">{childItem.title}</a>
                                                            </li>}
                                                        {/* Enlaces externos */}
                                                        {childItem.target === 'param' &&
                                                            <li>
                                                                <Link className='mainNavMenu_dropdownBox_link' to={`${childItem?.acf?.url}`}>{childItem.title}</Link>
                                                            </li>}
                                                    </React.Fragment>

                                                ))
                                            ) : null}
                                            {// Agrega elementos personalizados aquí para el menú 
                                                myItem.title === 'myItem.title'/* Agregar aquí el nombre del botón */ ? (
                                                    <div>
                                                        <p>Contenido personalizado 1</p>
                                                        <p>Contenido personalizado 2</p>
                                                    </div>
                                                ) : null}
                                        </ul>
                                    </li>
                                </ul>
                            );
                        })
                    ) : null}
                </nav>

            </MediaScreenMenuBox>
            <MediaScreenBars onClick={() => handleMenu(show)} /> 
        </>)
}

export default MediaScreenMenu
