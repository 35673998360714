import React, { useState, useEffect } from 'react';

import UseHTML from '../../../components/hook/UseHTML'; 
import UseLang from '../../../components/hook/UseLang';
import Alert from '../../../components/interface/alert/Alert';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const FellowshipProgramHeader = props => { 
    const { content, title, button, members } = props;
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        let targetDate;
        if (!members){
            targetDate = new Date('2024-07-27'); 
        }else{
            targetDate = new Date('2024-08-22'); 
        }
        const currentDate = new Date(); // obtengo la fecha de hoy
    
        if (currentDate >= targetDate) {
            setIsOver(true);
        }
      }, [members]);
    

    return (
        <article className='fellowship_program_main_header'> 
            <div>
                <UseTitleHTML html={title} />
                <UseHTML html={content} />
                {isOver ?
                    <Alert red>
                        <UseLang es><p>El período para solicitar becas ha finalizado.</p></UseLang>
                        <UseLang en><p>The period to apply for grants is over.</p></UseLang>
                        <UseLang pt_br><p>O período de pedido de bolsa acabou.</p></UseLang>
                    </Alert> 
                :
                    <>{button ? 
                        <a href={`${button.button_url}`} target="_blank" className='button blue'  rel="noreferrer">{button.button_name}</a>
                    : undefined }</> 
                }
            </div>
        </article>
    )
}

export default FellowshipProgramHeader
