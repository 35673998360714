import React, {useState, useEffect} from 'react'; 
import Cover from '../../../components/design/cover/Cover';
import UseHTML from '../../../components/hook/UseHTML';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const InfoCover = props => { 
    const { loading , title, content, cover} = props 
 
    const [renderedUrl, setRenderedUrl] = useState(null); 
    const urlCover = cover?._links?.["wp:featuredmedia"]?.[0]?.href;

  
    useEffect(() => {
      const fetchMediaDetails = async () => {
        try {
          const response = await fetch(urlCover);
          const data = await response.json(); 
          // Acceder a la URL
          const url = data?.guid?.rendered;
          setRenderedUrl(url);
        } catch (error) {
          console.error("Error al recoger los datos de media:", error);
        }
      };
  
      fetchMediaDetails();
    }, [urlCover]);

     
    return (
        <Cover className='content' 
            loading={loading} 
            src={renderedUrl}> 
            <div>
                <UseTitleHTML html={title} />
                <UseHTML html={content} />
            </div> 
        </Cover>
    )
}

export default InfoCover
