import React from 'react'
import config from '../../configuration/config'

const LogoWhite = () => {
    return (
        <img src="/logo.svg" alt={`${config.currentEvent}-${config.lacnogEvent}`}></img>
    )
}

export default LogoWhite
