import React from 'react';
import { useParams } from 'react-router-dom';
import FellowshipProgramBodyMember from './FellowshipProgramBodyMember';
import './fellowship-program.css'

const LacnicFellowshipProgramMembers = props => {
    const { fellowship } = useParams()

    if (fellowship === 'objetivos' || fellowship === 'purpose' || fellowship === undefined) {
        return (
            <FellowshipProgramBodyMember page_number='1'
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/purpose`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/objetivos'  
                {...props} />
        )
    }
    if (fellowship === 'criterios' || fellowship === 'selection-criteria') {
        return (
            <FellowshipProgramBodyMember page_number='2'
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/selection-criteria`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/criterios'
                {...props} />
        )
    }
    if (fellowship === 'obligaciones-de-los-postulantes-seleccionados' || fellowship === 'obligations-of-the-successful-applicants' || fellowship === 'obrigacoes-dos-candidatos-selecionados') {
        return (
            <FellowshipProgramBodyMember page_number='3'
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/obligations-of-the-successful-applicants`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/obligaciones-de-los-postulantes-seleccionados'
                {...props} />
        )
    }
    if (fellowship === 'alcance' || fellowship === 'scope') {
        return (
            <FellowshipProgramBodyMember page_number='4'
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/scope`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/alcance'
                {...props} />
        )
    }
    if (fellowship === 'fechas-importantes' || fellowship === 'important-dates' || fellowship === 'datas-importantes') {
        return (
            <FellowshipProgramBodyMember page_number='5'
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/important-dates`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/fechas-importantes'
                {...props} />
        )
    } 
    if (fellowship === 'postulantes-seleccionados' || fellowship === 'selected-fellows' || fellowship === 'candidatos-selecionados' ) {
        return (
            <FellowshipProgramBodyMember page_number='6' 
                url_en={`/en/how-to-participate/fellowship-program-lacnic-members/selected-fellows`} 
                url_es='/es/como-participar/programa-de-becas-asociados-lacnic/postulantes-seleccionados'
                {...props} />
        )
    } 
}

export default LacnicFellowshipProgramMembers
