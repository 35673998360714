import React, { useState } from 'react';
import useUrlParams from '../../../hook/useUrlParams';
import Modal from '../../../interface/modal/Modal';
import { useNavigate } from 'react-router-dom';
import './stand.css';
import StandDates from './StandDates';
import StandTopics from './StandTopics';
import UseLang from '../../../hook/UseLang';
import config from '../../../configuration/config';

const Stand = props => {
    const navigate = useNavigate();
    const { paramValue, display, closeModal } = useUrlParams('lacnicStand', ['agenda'])
    const [activeTab, setActiveTab] = useState('6'); 

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`?lacnicStand=agenda&date=${tab}`);
    };

    return (
        <Modal show={display} paramValue={'lacnicStand'} className='lacnicStand' onCancel={closeModal}>
            {paramValue === 'agenda' ?
                <div>
                    <div className='stand_header'>
                        <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
                        <div>
                            <UseLang es><h2>Stand de LACNIC</h2>
                            <p>Durante toda la semana nuestros expertos ofrecerán información acerca de:</p></UseLang>
                            <UseLang en><h2>LACNIC Stand</h2><p>During the week our staff will be available to answer questions about:</p></UseLang>
                            <UseLang pt_br><h2>Estande de LACNIC</h2><p>Durante a semana toda, nossos especialistas vão oferecer informações sobre:</p></UseLang>
                        </div>
                    </div>
                    <div className='stand_agenda'>
                        <div className='stand_dates'>
                            <button className={`tab_button ${activeTab === '6' ? 'active' : ''}`} onClick={() => handleTabClick('6')}>06/10 <StandDates monday /></button>
                            <button className={`tab_button ${activeTab === '7' ? 'active' : ''}`} onClick={() => handleTabClick('7')}>07/10 <StandDates tuesday /></button>
                            <button className={`tab_button ${activeTab === '8' ? 'active' : ''}`} onClick={() => handleTabClick('8')}>08/10 <StandDates wednesday /></button>
                            <button className={`tab_button ${activeTab === '9' ? 'active' : ''}`} onClick={() => handleTabClick('9')}>09/10 <StandDates thursday /></button>
                            <button className={`tab_button ${activeTab === '10' ? 'active' : ''}`} onClick={() => handleTabClick('10')}>10/10 <StandDates friday /></button>
                        </div>
                        <div className='stand_topics'>
                            {activeTab === '6' &&
                                <div> 
                                    <div className='stand_start_time'>11:00 - 12:00 ({config.eventTimeZone})</div>
                                    <StandTopics frida />
                                    <div className='stand_start_time'>12:00 - 13:00 ({config.eventTimeZone})</div>
                                    <StandTopics transferencia ipv4 />
                                    <div className='stand_start_time'>13:30 - 15:00 ({config.eventTimeZone})</div>
                                    <StandTopics frida />
                                    <div className='stand_start_time'>15:00 - 17:00 ({config.eventTimeZone})</div>
                                    <StandTopics lideres />
                                    <div className='stand_start_time'>17:00 - 18:00 ({config.eventTimeZone})</div>
                                    <StandTopics campus />
                                </div>
                            }
                            {activeTab === '7' &&
                                <div> 
                                    <div className='stand_start_time'>09:00 - 13:00 ({config.eventTimeZone})</div>
                                    <StandTopics transferencia ipv4 /> 
                                    <div className='stand_start_time'>13:30 - 15:00 ({config.eventTimeZone})</div>
                                    <StandTopics twoFA />
                                    <div className='stand_start_time'>15:00 - 16:00 ({config.eventTimeZone})</div>
                                    <StandTopics twoFA frida /> 
                                    <div className='stand_start_time'>16:00 - 18:00 ({config.eventTimeZone})</div>
                                    <StandTopics transferencia ipv4 /> 
                                </div>
                            }
                            {activeTab === '8' && 
                                <div> 
                                     <div className='stand_start_time'>09:00 - 11:00 ({config.eventTimeZone})</div>
                                    <StandTopics twoFA />
                                    <div className='stand_start_time'>11:00 - 13:00 ({config.eventTimeZone})</div>
                                    <StandTopics ipv6 />
                                    <div className='stand_start_time'>13:30 - 16:00 ({config.eventTimeZone})</div>
                                    <StandTopics raices /> 
                                </div>
                            }
                            {activeTab === '9' && 
                                <div> 
                                    <div className='stand_start_time'>09:00 - 11:00 ({config.eventTimeZone})</div>
                                    <StandTopics transferencia ipv4 />
                                    <div className='stand_start_time'>11:00 - 13:00 ({config.eventTimeZone})</div>
                                    <StandTopics membership />
                                    <div className='stand_start_time'>13:30 - 15:00 ({config.eventTimeZone})</div>
                                    <StandTopics twoFA frida />
                                    <div className='stand_start_time'>15:00 - 16:00 ({config.eventTimeZone})</div>
                                    <StandTopics lideres />
                                    <div className='stand_start_time'>16:00 - 18:00 ({config.eventTimeZone})</div>
                                    <StandTopics campus />
                                </div>
                            }
                            {activeTab === '10' && 
                                <div> 
                                    <div className='stand_start_time'>09:00 - 10:00 ({config.eventTimeZone})</div>
                                    <StandTopics frida />
                                    <div className='stand_start_time'>10:00 - 11:00 ({config.eventTimeZone})</div>
                                    <StandTopics transferencia ipv4 />
                                    <div className='stand_start_time'>11:00 - 13:30 ({config.eventTimeZone})</div>
                                    <StandTopics membership />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : undefined}

        </Modal>
    )
}

export default Stand
