import React from 'react';
import RoomSlot from './RoomSlot';
import UseLang from '../../../../components/hook/UseLang'; 

const ActivityDetails = ({ activity }) => {
   /*   */
    return (
        <>
            {activity.map((item, id) => (
                <div key={id}><div className='ActivityName'>
                    <UseLang es>{item?.acf?.info_es?.title}</UseLang> 
                    <UseLang en>{item?.acf?.info_en?.title}</UseLang> 
                    <UseLang pt_br>{item?.acf?.info_pt_br?.title}</UseLang> 
                    </div>
                    
                    <RoomSlot>{item?.acf?.room}</RoomSlot>
                </div>

            ))}
        </>
    );
};

export default ActivityDetails;
