import React from 'react';
import Organizers from '../../../components/design/organizers/Organizers';
import UseHTML from '../../../components/hook/UseHTML'; 

const WelcomeContent = props => {
    const {  about_event, organizers } = props;
 

    return (
        <article className='welcome'>
            <div>
                <div className='about_event'>
                    <>
                     
                        <UseHTML html={about_event} /> 
                        <Organizers organizers={organizers} />
                    </>
                </div>
            </div>
        </article>

    )
}

export default WelcomeContent
