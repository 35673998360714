import React from 'react'
import UseLang from '../../../hook/UseLang';

const StandTopics = props => {
    const {campus, frida, ipv4, measurement, ipv6, raices, csirt, feedback, twoFA, milacnic, rpki, policy, lideres, membership} = props;
    return (
        <p>
            <UseLang es>
                {membership ? <span>Membresia</span> : undefined}
                {feedback ? <span>Feedback de sistemas</span> : undefined}
                {twoFA ? <span>2FA</span>: undefined}
                {milacnic ? <span>MiLACNIC</span>: undefined}
                {campus ? <span>Campus LACNIC</span> : undefined}
                {frida ? <span>FRIDA</span> : undefined}
                {ipv4 ? <><span>Solicitud de Recursos</span><span>Agotamiento IPv4</span><span>Transferencias</span></> : undefined}
                {measurement ? <span>Sondas de medición</span> : undefined}
                {ipv6 ? <><span>IPv6</span><span>I+D</span></> : undefined}
                {csirt ? <span>LACNIC CSIRT</span> : undefined}
                {raices ? <><span>+RAÍCES</span><span>IRR</span><span>RPKI</span><span>Sondas de medición</span></> : undefined}
                {rpki ? <span>RPKI</span> : undefined}
                {policy ? <span>Desarrollo de Políticas</span> : undefined}
                {lideres ? <span>Líderes + Apoyo a iniciativas regionales de gobernanza de Internet (NRIs)</span> : undefined}
            </UseLang>
            <UseLang en>
                {membership ? <span>Membership services</span> : undefined}
                {feedback ? <span>Systems feedback</span> : undefined}
                {twoFA ? <span>2FA</span>: undefined}
                {milacnic ? <span>MiLACNIC</span>: undefined}
                {campus ? <span>Campus LACNIC</span> : undefined}
                {frida ? <span>FRIDA - IT Women Mentoring</span> : undefined}
                {ipv4 ? <><span>Resource Requests</span><span>IPv4 Exhaustion</span><span>Transfers</span></> : undefined}
                {measurement ? <span>Measurement probes</span> : undefined}
                {ipv6 ? <><span>IPv6</span><span>I+D</span></> : undefined} 
                {csirt ? <span>LACNIC CSIRT</span> : undefined}
                {raices ? <><span>+RAICES</span><span>IRR</span><span>RPKI</span><span>Measurement Probes</span></> : undefined}
                {rpki ? <span>RPKI</span> : undefined}
                {policy ? <span>Policy development</span> : undefined}
                {lideres ? <span>Líderes + Support for regional Internet Governance initiatives (NRIs)</span> : undefined}
            </UseLang>
            <UseLang pt_br>
                {membership ? <span>Membresia</span> : undefined}
                {feedback ? <span>Feedback de sistemas</span> : undefined}
                {twoFA ? <span>2FA</span>: undefined}
                {milacnic ? <span>MiLACNIC</span>: undefined}
                {campus ? <span>Campus LACNIC</span> : undefined}
                {frida ? <span>FRIDA - Mentoria para Mulheres Técnicas</span> : undefined}
                {ipv4 ? <><span>Pedido de Recursos</span><span>Esgotamento IPv4</span><span>Transferências</span></> : undefined}
                {measurement ? <span>Sondas de medição</span> : undefined}
                {ipv6 ? <><span>IPv6</span><span>I+D</span></> : undefined} 
                {csirt ? <span>LACNIC CSIRT</span> : undefined}
                {raices ? <><span>+RAÍZES</span><span>IRR</span><span>RPKI</span><span>Sondas de medição</span></> : undefined}
                {rpki ? <span>RPKI</span> : undefined}
                {policy ? <span>Desenvolvimento de Políticas</span> : undefined}
                {lideres ? <span>Líderes + Apoio a iniciativas regionais de Governança da Internet (NRI)</span> : undefined}
                 
            </UseLang>
        </p>
    )
}

export default StandTopics
