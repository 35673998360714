import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationButton = props => {
    const { item, onClick}= props;

     

    return (
        <div className='presentation_video_button'  onClick={onClick}>
            <UseLang es>
                {!item.info_es !== '' ? <button className='button blue'>Descripción [+]</button> : undefined}
            </UseLang>
            <UseLang en>
                {!item.info_en !== '' ? <button className='button blue'>Description [+]</button> : undefined}
            </UseLang>
            <UseLang pt_br>
                {!item.info_pt_br !== '' ? <button className='button blue'>Descrição [+]</button> : undefined}
            </UseLang>
             
        </div>
    )
}

export default PresentationButton

