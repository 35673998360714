import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';
import UseLang from '../../../components/hook/UseLang';
import './live-streaming.css'
import config from '../../../components/configuration/config';

const Player = props => {
    const {content, title} = props;

    const eventDate = Date.parse(config.eventDate);
    const today = Date.parse(new Date());
    return (
        <article className='player'>
            <div> 
                <UseTitleHTML html={title} />
                {eventDate < today ? <> {content === '' ? <div className='will-soon-player'>
                    <UseLang es>Pronto comenzará la transmisión en vivo. En breve agregaremos el reproductor.</UseLang>
                    <UseLang en>The live stream will start shortly. We will soon add the player.</UseLang>
                    <UseLang pt_br>A transmissão ao vivo começará em breve. Em breve adicionaremos o player.</UseLang>
                </div> 
                
                :
                <UseHTML html={content}/> 
                }</>: null}
            </div>
        </article>
    )
}

export default Player
