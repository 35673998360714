import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main'
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import AboutLACNICEventsContent from './AboutLACNICEventsContent';
import AboutSponsorsContent from './AboutSponsorsContent';
import AboutStatistics from './AboutStatistics';
import CodeOfConduct from './CodeOfConduct'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';
import UsePost from '../../../components/hook/UsePost';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './about-lacnic.css';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';  
import settings from '../../../components/configuration/settings';

const AboutLACNICEvents = props => { 
    const {   handleApiURL, handleID, handleLoading, data } = useContext(PostContext); 
    const [loading, setLoading] = useState(true);  
 
    return (
        <>{data ? <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id={settings.about_page_id} >
                <Header title={data?.title?.rendered} url_en='/en/how-to-participate/about-lacnic-events' url_pt_br='/pt-br/como-participar/o-que-e-um-evento-do-lacnic' url_es='/es/como-participar/que-es-un-evento-de-lacnic' loading={loading} {...props} />
                <Main className='about_event' status={data?.status} loading={loading}> 
                    <section className='main_section'>
                      <UseTimeOut timeout='200' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            <StickyHeader/>
                            <AboutLACNICEventsContent html={data?.content?.rendered} /> 
                            <AboutStatistics />
                            <AboutSponsorsContent />
                            <CodeOfConduct />
                         </UseTimeOut> 
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost> 
        </UseLangLocation> : undefined}</>
    )
}
export default AboutLACNICEvents;
