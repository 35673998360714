import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/homepage/content/HomePage';
import AboutLACNICEvents from './pages/participate/about-lacnic-events/AboutLACNICEvents';
import NoMatch from './pages/no-match/NoMatch';
import UploadBiography from './pages/programme/upload/biography/UploadBiography';
import UploadPresentation from './pages/programme/upload/presentations/UploadPresentation';
import Sponsorship from './pages/sponsorship/event-sponsorships/Sponsorship';
import LacnicFellowshipProgram from './pages/participate/lacnic-fellowship-program/LacnicFellowshipProgram';
import HowToParticipate from './pages/participate/how-to-participate/HowToParticipate';
import LiveStreaming from './pages/multimedia/live-streaming/LiveStreaming';
import Info from './pages/hotels-travel/info/Info';
import TravelDiscounts from './pages/hotels-travel/travel-discounts/TravelDiscounts';
import { BiographyContext } from './context/DataContext';
import Programme from './pages/programme/agenda/programme/Programme';
import Speakers from './pages/programme/speakers/speakers-list/Speakers';
import Bios from './pages/programme/speakers/bios/Bios';
import Hotels from './pages/hotels-travel/hotels/Hotels';
import Visas from './pages/hotels-travel/visas/Visas';
import Fees from './pages/registration/fees/Fees';
import PreparatoryActivity from './pages/programme/preparatory-activity/PreparatoryActivity'; 
import slugify from 'react-slugify'; 
import WelcomeKit from './pages/participate/welcome-kit/WelcomeKit';
import PresentationList from './pages/programme/agenda/presentations-list/PresentationsList';
import Networking from './pages/participate/networking/Networking';
import Ftl from './pages/participate/ftl/Ftl';  
/* import FellowshipDraw from './pages/participate/fellowship-draw/FellowshipDraw';
 */import Transport from './pages/hotels-travel/transport/Transport';
import config from './components/configuration/config'; 
import './App.css'  
import LacnicFellowshipProgramMembers from './pages/participate/lacnic-fellowship-program/LacnicFellowshipProgramMembers';
 
function App() {
  const {bios} = useContext(BiographyContext)  
  return (
    
    <Routes>  
      <Route path={`/`} element={<HomePage/>}> </Route>
      <Route path={`/es`} element={<HomePage/>} > </Route>
      <Route path={`/en`} element={<HomePage/>}> </Route>
      <Route path={`/pt-br`} element={<HomePage/>}> </Route>

      <Route path={`/es/como-participar/ftl`} element={<Ftl/>}></Route>
      <Route path={`/en/how-to-participate/ftl`} element={<Ftl/>}></Route>
      <Route path={`/pt-br/como-participar/ftl`} element={<Ftl/>}></Route>

      <Route path={`/es/patrocinios`} element={<Sponsorship url='patrocinios'/>} />
      <Route path={`/en/sponsorship`} element={<Sponsorship url='sponsorship'/>} />
      <Route path={`/pt-br/patrocinios`} element={<Sponsorship url='patrocinios'/>} />

      <Route path={`/es/patrocinios/patrocinios`} element={<Sponsorship url='patrocinios'/>}></Route> 
      <Route path={`/en/sponsorship/sponsorship`} element={<Sponsorship url='sponsorship'/>}></Route>
      <Route path={`/pt-br/patrocinios/patrocinios`} element={<Sponsorship url='patrocinios'/>}></Route> 

      <Route path={`/es/como-participar/que-es-un-evento-de-lacnic`} element={<AboutLACNICEvents/>}></Route>
      <Route path={`/en/how-to-participate/about-lacnic-events`} element={<AboutLACNICEvents/>}></Route>
      <Route path={`/pt-br/como-participar/o-que-e-um-evento-do-lacnic`} element={<AboutLACNICEvents/>}></Route> 

      <Route path={`/es/programa/subir-biografia`} element={<UploadBiography/>}></Route>
      <Route path={`/en/programme/upload-biography`} element={<UploadBiography/>}></Route>
      <Route path={`/pt-br/programa/carregar-biografia`} element={<UploadBiography/>}></Route>

      <Route path={`/es/programa/subir-presentacion`} element={<UploadPresentation/>}></Route>
      <Route path={`/en/programme/upload-presentation`} element={<UploadPresentation/>}></Route>
      <Route path={`/pt-br/programa/carregar-apresentacao`} element={<UploadPresentation/>}></Route>

      <Route path={`/es/como-participar/programa-de-becas-de-lacnic`} element={<LacnicFellowshipProgram />}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram  />} />
      </Route>
      <Route path={`/en/how-to-participate/lacnic-fellowship-program`} element={<LacnicFellowshipProgram  />}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram />} />
      </Route>
      <Route path={`/pt-br/como-participar/programa-de-bolsas-do-lacnic`} element={<LacnicFellowshipProgram  />}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram />} />
      </Route>

      <Route path={`/es/como-participar/programa-de-becas-asociados-lacnic`} element={<LacnicFellowshipProgramMembers  />}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgramMembers  />} />
      </Route>
      <Route path={`/en/how-to-participate/fellowship-program-lacnic-members`} element={<LacnicFellowshipProgramMembers  />}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgramMembers />} />
      </Route>
 

      <Route path={`/es/como-participar`} element={<HowToParticipate/>}></Route>
      <Route path={`/en/how-to-participate`} element={<HowToParticipate/>}></Route>
      <Route path={`/pt-br/como-participar`} element={<HowToParticipate/>}></Route>

      <Route path={`/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/es/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/en/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/pt-br/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/media/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/es/media/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/en/media/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/pt-br/media/streaming`} element={<LiveStreaming/>}></Route>

      <Route path={`/es/hoteles-y-viaje/informacion-general`} element={<Info/>}></Route>
      <Route path={`/en/hotels-and-travel/general-information`} element={<Info/>}> </Route>
      <Route path={`/pt-br/hoteis-e-viagem/informacoes-gerais`} element={<Info/>}></Route>
      
      <Route path={`/es/hoteles-y-viaje/descuentos-en-pasajes`} element={<TravelDiscounts/>} />
      <Route path={`/en/hotels-and-travel/travel-discounts`} element={<TravelDiscounts/>} />
      <Route path={`/pt-br/hoteis-e-viagem/descontos-em-passagens`} element={<TravelDiscounts/>} /> 

      <Route path={`/es/hoteles-y-viaje/servicio-de-traslados`} element={<Transport/>} />
      <Route path={`/en/hotels-and-travel/transportation-services`} element={<Transport/>} />
      <Route path={`/pt-br/hoteis-e-viagem/servico-de-transporte`} element={<Transport/>} /> 
     
      <Route path={`/es/programa/agenda/`} element={<Programme event_id={config.currentEventID} />}>
        <Route path={`:session`} element={<Programme />} />
      </Route>
      <Route path={`/en/programme/agenda/`} element={<Programme event_id={config.currentEventID_en} />}>
        <Route path={`:session`} element={<Programme />} />
      </Route>
      <Route path={`/pt-br/programa/agenda/`} element={<Programme event_id={config.currentEventID_pt_br} />}>
        <Route path={`:session`} element={<Programme />} />
      </Route> 
      <Route path={`/es/programa/oradores`} element={<Speakers/>} />
      <Route path={`/en/programme/speakers`} element={<Speakers/>} />
      <Route path={`/pt-br/programa/palestrantes`} element={<Speakers/>} />
      
      
      {bios.map((item, id) => 
          <Route key={id} path={`/es/programa/oradores/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}  
      {bios.map((item, id) => 
          <Route key={id} path={`/en/programme/speakers/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}  
      {bios.map((item, id) => 
          <Route key={id} path={`/pt-br/programa/palestrantes/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}   

    <Route path={`/es/hoteles-y-viaje/hoteles`} element={<Hotels/>} />
      <Route path={`/en/hotels-and-travel/hotels`} element={<Hotels/>} />
      <Route path={`/pt-br/hoteis-e-viagem/hoteis`} element={<Hotels/>} />

      <Route path={`/es/hoteles-y-viaje/requerimientos-visas`} element={<Visas/>} />
      <Route path={`/en/hotels-and-travel/visa-requirements`} element={<Visas/>} />
      <Route path={`/pt-br/hoteis-e-viagem/requisitos-vistos`} element={<Visas/>} />

      <Route path={`/es/registro`} element={<Fees/>} />
      <Route path={`/en/registration`} element={<Fees/>} />
      <Route path={`/pt-br/inscricao`} element={<Fees/>} />

      <Route path={`/es/programa/actividad-preparatoria`} element={<PreparatoryActivity/>}></Route>
      <Route path={`/en/programme/preparatory-activity`} element={<PreparatoryActivity/>}></Route>
      <Route path={`/pt-br/programa/atividade-preparatoria`} element={<PreparatoryActivity/>}></Route>  
      
      <Route path={`/es/como-participar/kit-de-bienvenida`} element={<WelcomeKit/>}></Route>
      <Route path={`/en/how-to-participate/welcome-kit`} element={<WelcomeKit/>}></Route>
      <Route path={`/pt-br/como-participar/kit-de-boas-vindas`} element={<WelcomeKit/>}></Route> 

      <Route path={`/es/programa/presentaciones-y-videos`} element={<PresentationList   />}></Route>
      <Route path={`/en/programme/presentations-and-videos`} element={<PresentationList   />}></Route>
      <Route path={`/pt-br/programa/apresentacoes-e-videos`} element={<PresentationList   />}></Route>  

      <Route path={`/es/como-participar/networking`} element={<Networking/>}></Route>
      <Route path={`/en/how-to-participate/networking`} element={<Networking/>}></Route>
      <Route path={`/pt-br/como-participar/networking`} element={<Networking/>}></Route> 

       

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;
