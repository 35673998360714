import React, { useState } from 'react';
import UseHTML from '../../../components/hook/UseHTML';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';

const TravelAgencies = ({ data }) => {
  const { title, content } = data ?? {}; 
  const [copyClipBoard, setCopyClipBoard] = useState(false);
   
  const handleEmailClick = async (email, agent) => {
    try {
      await navigator.clipboard.writeText(email);
      setCopyClipBoard(agent);
      setTimeout(() => {
        setCopyClipBoard(false);
      }, 3000);

      const subject = 'LACNIC 41 - Descuentos en pasajes';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoLink;
    } catch (error) {
      //manejo de errores 
      console.error('Error copying to clipboard:', error);
    }
  };


  return (
    <article className="TravelAgencies">
      <div> 
        <UseTitleHTML html={title?.rendered} />
        <UseHTML html={content?.rendered} />
        <div className='CopyClipBoardAgent'>
            <div>
                <h2>Jorge Martínez</h2>
                <div> 
                    <button onClick={() => handleEmailClick('mbergara@jorgemartinez.com.uy', 'mbergara')}>
                    mbergara@jorgemartinez.com.uy {copyClipBoard === 'mbergara' ? null : null }
                    </button> 
                    <button onClick={() => handleEmailClick('ifarina@jorgemartinez.com.uy', 'ifarina')}>
                    ifarina@jorgemartinez.com.uy {copyClipBoard === 'ifarina' ? null : null }
                    </button> 
                </div>
            </div>
            <div>
                <h2>Jetmar</h2>
                <button onClick={() => handleEmailClick('zam@jetmar.com.uy', 'zam')}>
                zam@jetmar.com.uy {copyClipBoard === 'zam' ? null : null }
                </button>
            </div>
        </div>
         
         
      </div>
    </article>
  );
};

export default TravelAgencies;
