import React, { useEffect, useState } from 'react';
import './main-image.css';
import UseHTML from '../../../components/hook/UseHTML';

const MainImage = props => {
    const { featured_content, featured_logo, featured_image } = props;
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 890);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 890);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const bannerStyles = {
        backgroundImage: isSmallScreen
            ? `url(${featured_image})`
            : `url(${featured_logo}), url(${featured_image})`,
        backgroundPosition: isSmallScreen ? '0% 50%': 'center 80px, 0% 50%',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: isSmallScreen ? 'cover': '720px, cover' 
    };

    return (
        <div className="main_image" style={bannerStyles}>
            <div className="main_image_backdrop_filter">
                <div>
                    <div className='homepage_header'>
                        <UseHTML html={featured_content} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainImage;
