import React, { useContext, useEffect, useState } from 'react' 
import Ellipsis from '../../../../../components/animations/loadings/Ellipsis';
import TimeCheckbox from '../../table-functionalities/TimeCheckbox'; 
import PresentationRow from './presentation-row/PresentationRow';
import { SessionContext } from '../../../../../context/DataContext';
import DayPicker from '../../table-functionalities/DayPicker'; 
import './presentations.css';

const Presentations = props => {
    const { current_session } = props;
    const { agendaData, handleCurrentSession } = useContext(SessionContext);
    useEffect(() => {
        handleCurrentSession(current_session);
    }, [current_session, handleCurrentSession]);
     
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);    
    return (
        <>{agendaData[0] && <>
            <>{!loading
                ?
                <>  
                    <TimeCheckbox />
                    <DayPicker agendaData={agendaData} /> 
                    <PresentationRow agendaData={agendaData}/> 
                </>
                :
                <article>
                    <div>
                        <Ellipsis />
                    </div>
                </article>
            }</>
        </>}
        </>
    )
}

export default Presentations
