import React, { useContext } from 'react';
import { TimeZoneContext } from '../../../../../../context/DataContext';
import config from '../../../../../../components/configuration/config';
const { format: formatTz } = require("date-fns-tz");

const PresentationTime = props => {
  const { start_time, ending_time } = props;
  const { myTimeZone, gmt, utc } = useContext(TimeZoneContext); 

  const [datePart] = start_time.split(' ');
  const [day, month, year] = datePart.split('/'); 
   
  /* startTime */
  const startLocalTime =`${myTimeZone === config.eventTimeZone ? `${start_time}` : `${start_time} ${config.utcEventTimeZone}`}`  
  const useStartLocalTime = new Date(startLocalTime); 
  const utcStartLocalTime = `${myTimeZone === config.eventTimeZone ? `${start_time} ${gmt}` : `${start_time}`}` 
  const useUtcStartLocalTime = new Date(utcStartLocalTime); 
  const startTime = ` ${myTimeZone === 'localtime' ? `${formatTz(useUtcStartLocalTime, 'HH:mm')}` : `${formatTz(useStartLocalTime, 'HH:mm')}`}`;  

  /* endingTime */
  const endingLocalTime =`${myTimeZone === config.eventTimeZone ? `${ending_time}` : `${ending_time} ${config.utcEventTimeZone}`}`  
  const useEndingLocalTime = new Date(endingLocalTime); 
  const utcEndingLocalTime = `${myTimeZone === config.eventTimeZone ? `${ending_time} ${gmt}` : `${ending_time}`}` 
  const useUtcEndingLocalTime = new Date(utcEndingLocalTime); 
  const endingTime = ` ${myTimeZone === 'localtime' ? `${formatTz(useUtcEndingLocalTime, 'HH:mm')}` : `${formatTz(useEndingLocalTime, 'HH:mm')}`}`;  
  
  return (
    <p className='presentation_start_time'> 
      {`${day}/${month}/${year}`} - {startTime} - {endingTime} {myTimeZone === 'localtime' ? `(` + config.currentEventCountry + `)` : `(${utc})`}
    </p>
  );
};

export default PresentationTime;