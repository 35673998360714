import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './popup.css'

const PopupOverlay = ({ show, children }) => {
  if (!show) {
    return null;
  }

  const portalRoot = document.getElementById('popup-hook');

  return ReactDOM.createPortal(children, portalRoot);
};

const Popup = ({ show, children }) => {
  const [showPopup, setShowPopupState] = useState(false);

  useEffect(() => {
    if (show) {
      // Verificar si el popup ya se mostró en esta sesión
      const popupShown = sessionStorage.getItem('popupShown');

      if (!popupShown) {
        // Si no se ha mostrado, mostrar el popup y marcar como mostrado
        setShowPopupState(true);
        sessionStorage.setItem('popupShown', 'true');
      }
    }
  }, [show]);

  const closePopup = () => {
    setShowPopupState(false);
  };

  return (
    <PopupOverlay show={showPopup}>
      <div className="popup">
        <div className='popup_before'  onClick={closePopup}></div>
        <div className="popup_content"> 
            <div className='closePopup'><button onClick={closePopup}><i class="icon-xmark" aria-hidden="true"></i></button></div>
            {children} 
        </div>
      </div>
    </PopupOverlay>
  );
};

export default Popup;
