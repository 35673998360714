import React, { useState, useEffect } from 'react';
import { SocialEventContext } from './DataContext';
import axios from "axios";
import config from '../components/configuration/config';

export const SocialEventState = ({ children }) => {

    const [agendaSocialData, setAgendaSocialData] = useState(null);
    const [error, setError] = useState(null);
    const [socialSessionId, setSocialSessionId] = useState(198);
    const isEvent = config.currentEventID;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=${socialSessionId ? socialSessionId : 198}&our_events=${isEvent}`);
                setAgendaSocialData(response?.data[0]);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [socialSessionId, isEvent]);

    const handleSocialId = (id) => {
        setSocialSessionId(id)
      }

      const [socialExists, setSocialExists] = useState({
        runnersExists: false,
        cocktailExists: false,
        socialEventExists: false
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Verificar si existen datos para la primera ruta
                const response198 = await axios.get('https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=198&our_events=179');
                setSocialExists(prevState => ({ ...prevState, runnersExists: response198.data.length > 0 }));

                // Verificar si existen datos para la segunda ruta
                const response199 = await axios.get('https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=199&our_events=179');
                setSocialExists(prevState => ({ ...prevState, cocktailExists: response199.data.length > 0 }));

                // Verificar si existen datos para la segunda ruta
                const response200 = await axios.get('https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=200&our_events=179');
                setSocialExists(prevState => ({ ...prevState, socialEventExists: response200.data.length > 0 }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  return (
    <SocialEventContext.Provider value={{ 
        agendaSocialData, error, socialSessionId, handleSocialId, socialExists
    }}>
      {children}
    </SocialEventContext.Provider>
  );
}



 