import React from 'react';
import './featured-links.css';
import { Link } from 'react-router-dom';

const FeaturedLinks = props => {
    const { featured_links } = props; 

    return (
        <>
          {featured_links && Array.isArray(featured_links) && (
            <div className='featured_links'>
              <div className='link-container'>
                {featured_links?.map((info, index) => (
                  <Link to={info.url} key={index}>
                    <i className={info.icons}></i> 
                    <span> {info.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      );
}

export default FeaturedLinks
