import React, { useState, useEffect } from 'react'
import UseLang from '../../hook/UseLang'
import './organizers.css'
import settings from '../../configuration/settings';

const Organizers = props => {
  const [organizers, setOrganizers] = useState(null);
  const homeLink = `https://apievt.lacnic.net/wp-json/data/homepage/${settings.home_page_id}`;

  useEffect(() => {
    const fetchOrganizersDetails = async () => {
      try {
        const response = await fetch(homeLink);
        const data = await response.json();
        const url = data?.acf?.homepage?.organizers;
        setOrganizers(url);
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchOrganizersDetails();
  }, [homeLink]);

  return (
    <>
      {organizers && Array.isArray(organizers) ? (
        <div className='organizers_logos'>
          <h4>
            <UseLang es>Organizadores</UseLang>
            <UseLang en>Organizers</UseLang>
            <UseLang pt_br>Organizadores</UseLang>
          </h4>

          <div>
            {organizers.map((myItem, index) => (
              <a
                href={`${myItem.organizer_url}`}
                target="_blank"
                rel="noreferrer"
                key={index} // Usa el índice como clave
              >
                <img
                  src={`${myItem.organizer_logo}`}
                  alt={`${myItem.organizer_name}`}
                />
              </a>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Organizers;

