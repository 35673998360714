import React, { useContext } from 'react';
import { SponsorContext } from '../../../context/DataContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './sponsors-slider.css'
import SponsorCategories from './SponsorCategories';
import config from '../../configuration/config';

const SponsorsSlider = props => {
    const { sponsors_current_event } = useContext(SponsorContext)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 0,
        arrow: false,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 890,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const filteredSponsors = sponsors_current_event && Array.isArray(sponsors_current_event) && sponsors_current_event.filter(sponsor => {
        return sponsor?.acf?.sponsorship_flexible?.some(flexible => {
            return flexible.this_event.includes(`${config.currentEventID}`);
        });
    }); 
 

    return (<>
        {filteredSponsors ?
            <Slider className='sponsors_slider' {...settings}>
                {filteredSponsors.sort((a, b) => a?.acf?.sponsorship_flexible.find(item => item?.this_event.includes(`${config.currentEventID}`))?.order - b?.acf?.sponsorship_flexible.find(item => item?.this_event.includes(`${config.currentEventID}`))?.order)
          .map(sponsors => {
            const { acf, title, id } = sponsors;
            const sponsorshipFlex = sponsors?.acf?.sponsorship_flexible.find(item => item?.this_event.includes(`${config.currentEventID}`));
            
            return (
              <div key={id} className="sponsor slick-slide">
                <ul>
                  <li className='sponsor_list'>
                    <a href={`${acf.url}`} target="_blank" className='sponsor_link' rel="noreferrer" title={`${title.rendered}`}>
                      <img src={`${acf.logo}`} alt={`${title.rendered}`} />
                    </a>
                    <div className='sponsors_categories'>
                      <SponsorCategories id_category={`${sponsorshipFlex?.sponsorship_category_is}`} />
                    </div>
                  </li>
                </ul>
              </div>
            );
          })}
      </Slider>
            : <div></div>}</>
    )
}

export default SponsorsSlider

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className='arrow_slide next_slide'>
            <button onClick={onClick}>
                <i className='icon-angle-right'></i>
            </button>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className='arrow_slide prev_slide'>
            <button onClick={onClick}>
                <i className='icon-angle-left'></i>
            </button>
        </div>
    );
}


