import React from 'react';

const UseTitleHTML = ({ children, html, className, onClick }) => {
    return (
        <>
            {className ? (
                html === 'Asamblea General Asociados de LACNIC' ? (
                    <h1>Asamblea Ordinaria</h1>
                ) : html === 'LACNIC General Member Assembly' ? (
                    <h1>Ordinary Member Assembly</h1>
                ) : html === 'Assembléia Geral de Associados de LACNIC' ? (
                    <h1>Assembleia Ordinária</h1>
                ) : (
                    <h1 className={className} onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
                )
            ) : (
                <>
                    <h1 onClick={onClick} dangerouslySetInnerHTML={{ __html: html }} />
                    {children}
                </>
            )}
        </>
    );
};

export default UseTitleHTML;
