import React from 'react'
import WebinarModal from './WebinarModal';
import useUrlParams from '../../../components/hook/useUrlParams';
import UseTitleHTML from '../../../components/hook/UseTitleHTML';
import slugify from 'react-slugify';

const Webinar = props => {
    const { contents, data } = props;
    const consoleLinuxSlug = slugify(contents[2].label) 
    const { handleParam } = useUrlParams('webinar', ['propuestas-de-politicas-fpp', 'que-puedo-encontrar-en-el-evento', `${consoleLinuxSlug}`]);
    return (
        <>
            <article className='preparatory_activity_landing'>
                <div>
                    <UseTitleHTML html={data?.title?.rendered} /> 
                    <div className='select_webinar'>
                        <div>
                            <div>
                                <h2>{contents[0].label} <br/>{contents[1].label}</h2>
                                <p className="preparatory_date">24/04/24 - 18:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam('que-puedo-encontrar-en-el-evento')}>Info [+]</button>
                            </div>
                        </div> 
                        <div>
                            <div>
                                <h2>{contents[2].label} </h2>
                                <p className="preparatory_date">29/04/24 - 15:00 (UTC)</p>
                                <button className='button yellow' onClick={() => handleParam(`${consoleLinuxSlug}`)}>Info [+]</button>
                            </div>
                        </div> 
                         
                    </div>
                </div>
            </article>
            <WebinarModal consoleLinuxSlug={consoleLinuxSlug} contents={contents} />
        </>

    )
}

export default Webinar
