import React  from 'react'
import UseLang from '../../../../components/hook/UseLang' 
import UseHTML from '../../../../components/hook/UseHTML';


const Social = ({ data }) => {
    
    const styles = {
        socialImage: {
            background: `url('${data?.acf?.image}') 0% 0% / cover`
        } 
    };

    return ( 
        <div style={styles.socialImage} className='SocialEvent' >
            <div className='SocialEventData'>  
                    <UseLang es>
                    <UseHTML html={data?.acf?.info_es?.description} />
                    </UseLang>
                    <UseLang en>
                    <UseHTML html={data?.acf?.info_en?.description} />
                    </UseLang>
                    <UseLang pt_br>
                    <UseHTML html={data?.acf?.info_pt_br?.description} />
                    </UseLang> 
            </div> 
        </div> 
    )
  }
  
  export default Social
