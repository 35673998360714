import React from 'react'
import config from '../../configuration/config';

const LogoColor = props => {
    const {className, width} = props;
    return (
        <img src={`/logo-evento-color.svg`} alt={config.currentEvent} title={config.currentEvent} width={`${width}`} className={`${className}`} />
    )
}

export default LogoColor
