import React from 'react' 
import UseLang from '../../../components/hook/UseLang'
import useExpirationDate from '../../../components/hook/UseExpirationDate'


const RatesSlide = props => {
    const { discount_price, discount_info, price, discount, deadline } = props
 
     // Utilizar el hook para comprobar si la fecha ha pasado
    const isExpired = useExpirationDate(deadline);
     
    return (
        <div className='rate'>
            {discount === true
                ?
                <>
                    <div className='price discount_active'>
                        <div> 
                            {!isExpired ? (
                                <>
                                    <span className='regular'>USD {price}</span> 
                                    <span className='discount'>{discount_price && <>USD {discount_price}</>}</span>
                                </>
                            ) : (
                                <span>USD {price}</span> 
                            )}
                        </div>
                    </div>
                    {!isExpired ? (
                                <p className='discount_info'>{discount_info && discount_info}</p>
                            ) : (
                        undefined
                    )}
                    

                </>
                :
                <div className='price'>
                    <div>
                        <span>
                            {price === '0'
                                ?
                                <>
                                    <UseLang es>Sin costo</UseLang>
                                    <UseLang pt_br>Gratuito</UseLang>
                                    <UseLang en>Free of charge</UseLang>
                                </>
                            : price}
                        </span>
                    </div>
                </div>}
        </div>
    )
}

export default RatesSlide
