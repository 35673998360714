import React from 'react';
import HeadStuff from './HeadStuff';
import './Header.css'
import MainNavMenu from './mainNavMenu/MainNavMenu';
import MediaScreenMenu from './mediaScreenMenu/MediaScreenMenu'
import Announcements from '../../announcements/Announcements';


const Header = props => {
    const { className, loading, title, homePage } = props

    return (
        <>
            {homePage ?
                <header className={`header header_app myHeaderHomepage ${title ? '' : 'header_loading'} ${!loading ? '' : 'header_loading'}`}>
                   <HeaderContent {...props} />
                </header> :
                
                <header className={`header header_app myHeaderPage ${className} ${title ? '' : 'header_loading'} ${!loading ? '' : 'header_loading'}`}>
                    <HeaderContent {...props} />
                </header>
            }
        </>
    )
}

export default Header

const HeaderContent = (props) => {
    return (
        <div className='header_content'>
            <MediaScreenMenu />
             
            <MainNavMenu {...props} />
            <HeadStuff {...props} />
            <Announcements />
        </div>
    )
}
