import React, {useContext } from 'react'; 
import './DayPicker.css';
import { LangContext , SessionContext} from '../../../../context/DataContext';
import { parse } from 'date-fns';
import UseLang from '../../../../components/hook/UseLang';


const DayAgendaPicker = ({ agendaData }) => {
    const { handleDaySelection, selectedDateStr } = useContext(SessionContext);  
    const {myLang} = useContext(LangContext)
  const result = agendaData.reduce((acc, item) => {
    const selectedDate = item?.acf?.start_time.split(" ")[0]; // Obtenemos solo la fecha 
    if (!acc.includes(selectedDate)) {
      acc.push(selectedDate);
    }
    return acc;
  }, []);
   

  const getLocalizedDay = (dateString) => {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    let options = { weekday: 'long' };
  
    if (myLang === 'es') {
      return capitalize(parsedDate.toLocaleDateString('es-ES', options));
    } else if (myLang === 'pt-br') {
      return capitalize(parsedDate.toLocaleDateString('pt-BR', options));
    } else {
      return capitalize(parsedDate.toLocaleDateString('en-US', options));
    }
  };
  
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }; 

  // Función
  const handleDayClick = (item) => {
    if (item === selectedDateStr) {
      // Si se hace clic en la fecha seleccionada, establecerla como null
      handleDaySelection(null);
    } else {
      handleDaySelection(item);
    }
  };
   
  // Función 
  return (
    <>
      {result.length > 1 ? (
        <article>
          <div className='day_picker' id="DayPicker">
            <h2>
              <UseLang es>Seleccionar día</UseLang>
              <UseLang en> Select day</UseLang>
              <UseLang pt_br>Selecione o dia</UseLang>  
            </h2>
            <ul>
              {result?.sort((a, b) => new Date(a) - new Date(b))?.map((item, id) => (
                 
                <li
                  key={id}
                  className={`day ${item === selectedDateStr ? 'active' : ''}`}
                  onClick={() => handleDayClick(item)}
                >
                  {getLocalizedDay(item)} {item === selectedDateStr ? <i className='icon-xmark' /> : null}
                </li>
              ))}
            </ul>
          </div>
        </article>
      ) : null}
    </>
  );
};

export default DayAgendaPicker;
