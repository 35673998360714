import React, { useState, useEffect, useRef } from 'react' 
import useMenuContext from '../../../hook/UseMenu';
import Photos from '../photos/Photos'
import { Link } from 'react-router-dom'; 

const Apps = props => {
  const [app, setApp] = useState(false);
  const ref = useRef();
  const { isMenuApp, loading } = useMenuContext()

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setApp(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);

  const handleApp = () => {
    setApp(!app);
  }

  const handleButtonClick = (event) => {
    event.stopPropagation();
    handleApp();
  } 
  return (
    <>
      {Array.isArray(isMenuApp.items) && isMenuApp?.items.length > 0 ? <li className="select_apps" ref={ref}>
        {!app ? <button className="button_apps" onClick={handleButtonClick}>
          <i className="icon-grid-circle"></i>
        </button> : <button className="button_apps" onClick={handleButtonClick}>
          <i className="icon-xmark"></i>
        </button>}
        {app ?
          <div className="event_apps">
            <div>
              {!loading && isMenuApp?.items && Array.isArray(isMenuApp.items) ? (
                isMenuApp.items.map((myItem) => {
                  return (<>
                    <div key={myItem.ID}>
                       
                      {/* Enlaces externos */}
                      {myItem?.acf?.target === 'blank' &&
                        <li>
                            <a className='mainNavMenu_dropdownBox_link' href={`${myItem?.acf?.url}`} target='_blank' rel="noreferrer">
                            <i className={`${myItem?.acf?.icon}`}></i>
                            {myItem.title}</a>
                        </li>}
                      {/* Enlaces internos & QueryParams */}
                      {(myItem?.acf?.target === 'param' || myItem?.acf?.target === 'self') &&
                        <Link className='mainNavMenu_dropdownBox_link' to={`${myItem?.acf?.url}`}>
                          <i className={`${myItem?.acf?.icon}`}></i>
                          {myItem.title}
                        </Link>
                      }
                    </div></>)
                })
              ) : null} 
            </div>
          </div>
          : undefined
        }
      </li> : null}
      <Photos />
    </>
  );
}

export default Apps;
