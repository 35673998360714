import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const WhySponsor = props => {
    const {  contents } = props;
    return (
        <article className='why_sponsor'>
            <div>
                <h2>{contents[1]?.label}</h2> 
                <UseHTML html={contents[1]?.content} />
            </div>
        </article>
    )
}

export default WhySponsor
