import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'

const SponsorshipTable = props => {
    const {  contents } = props
    return (
        <article className='categories_and_benefit'>
            <div>
                <h2>{contents[0]?.label}</h2>
                <figure><UseHTML html={contents[0]?.content}/></figure>
            </div>
        </article>
    )
}

export default SponsorshipTable
