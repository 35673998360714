import React from "react";
import './count-down.css'
import CountDownText from "./CountDownText"; 
import TimerCountDown from "./TimerCountDown";
import config from "../../configuration/config"; 

const CountDown = ({title, content}) => {
  const eventDate = Date.parse(config.eventDate);
  const today = Date.parse(new Date());
  const time = eventDate - today;
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
 
  // Agregar la condición para mostrar o no el componente CountDown
  if (today < eventDate) {
    return ( 
        <div className="countdown"> 
          
          <div className="countdown_content">
            {days === 0 ? <h2>
              <TimerCountDown/>
            </h2>:
              <> 
              <h2>
                <CountDownText start='true' />
              </h2>
              <div className="counter_grid">
                <div className="counter_item">
                  <div className="counter_num">{days}</div>
                  <div className="counter_label">
                    <CountDownText days='true' />
                  </div>
                </div>
              </div>
              </>
            }
          </div>
        </div> 
    );
  } else {
    return null; // no mostrar nada si la fecha actual es posterior a la fecha del evento
  }
}

export default CountDown;
