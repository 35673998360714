import React from 'react'; 
import EventStatistics from '../../../components/sections/statistics/EventStatistics';
import StatisticsWrapper from '../../../components/sections/statistics/StatisticsWrapper';
import UseLang from '../../../components/hook/UseLang';
import config from '../../../components/configuration/config';

function AboutStatistics() { 
    return (
        <StatisticsWrapper>
            <h2>
                <UseLang es>¿Qué pasó en {config.pastEvent}?</UseLang>
                <UseLang en>What happened at {config.pastEvent}?</UseLang>
                <UseLang pt_br>O que aconteceu no {config.pastEvent}?</UseLang>
                  
            </h2>
            <EventStatistics />
        </StatisticsWrapper>
    )
}

export default AboutStatistics
