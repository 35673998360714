import React, { useContext } from 'react'
import Button from '../../../components/navegation/buttons/Button'
import { LangContext } from '../../../context/DataContext'

function PopupFtl({show}) {
    const {myLang} = useContext(LangContext);
    return (<> 
        <figure><img src="https://apievt.lacnic.net/wp-content/uploads/2024/01/foro-tecnico-de-lacnic-2023.png" alt="FTL" /></figure>
            <div className='popup_info'>
                {myLang === 'es' && <>
                    <h2>Convocatoria Foro Técnico</h2>
                    <p>LACNIC invita a la comunidad de Internet a enviar propuestas de presentaciones para integrar la agenda del FTL del próximo evento LACNIC 41, a realizarse en la ciudad de Panamá, Panamá del 6 al 10 de mayo de 2024. </p>
                    <Button to='https://lacnic41.lacnic.net/es/como-participar/ftl' className="blue">Ingrese aquí</Button>
                </>}
                {myLang === 'en' && <>
                    <h2>LACNIC Technical Forum – Call for Presentations</h2>
                    <p>LACNIC extends an invitation to the Internet community to participate in its call for presentations to be included in the LACNIC Technical Forum agenda for our upcoming event, LACNIC 41, to be held in Panama City, Panama, on 6 to 10 May 2024.</p>
                    <Button to='https://lacnic41.lacnic.net/en/how-to-participate/ftl' className="blue">Click here</Button>
                </>}
                {myLang === 'pt-br' && <>
                    <h2>Chamada Fórum Técnico</h2>
                    <p>LACNIC convida a comunidade da Internet para enviar propostas de apresentações que possam fazer parte da agenda do FTL do próximo evento, LACNIC 41 a ser realizado na Cidade do Panamá, Panamá, de 6 a 10 de maio de 2024.</p>
                    <Button to='https://lacnic41.lacnic.net/pt-br/como-participar/ftl' className="blue">Acesse aqui</Button>
                </>}
            </div>
            </>
    )
}

export default PopupFtl
