import React from 'react';  
import FooterWrapper from './FooterWrapper';
import FooterTop from './FooterTop';
import FooterBottom from './FooterBottom';
import CodeOfConduct from './CodeOfConduct';
 

function Footer(props) {
    const { className } = props 
    return (
        <>
            <CodeOfConduct /> 
            <FooterWrapper className={className}>  
                <FooterTop /> 
                <FooterBottom /> 
            </FooterWrapper>
        </>
    )
}

export default Footer
