import React, { useContext, useState } from 'react';
import { LangContext } from '../../../context/DataContext';
import Modal from '../../interface/modal/Modal';
import { Link } from 'react-router-dom';
import UseLang from '../../hook/UseLang';

const LangButton = props => {
    const { handleLanguaje } = useContext(LangContext)

    const [showLang, setShowLang] = useState(false)

    const openLang = () => setShowLang(true)
    const closeLang = () => setShowLang(false)

    const { url_es, url_en, url_pt_br, loading } = props

    const handleChangeLanguaje = (value) => {
        handleLanguaje(value);
        setShowLang(false);
    }

    return (
        <li className='select_lang'>
            <button className="button_lang" onClick={loading === false ? openLang : null}>
                <i className='icon-world-outline'></i>
                <UseLang es><span className='my_lang'>ES</span></UseLang>
                <UseLang en><span className='my_lang'>EN</span></UseLang>
                <UseLang pt_br><span className='my_lang my_lang_pt_br'>PT <br /> BR</span></UseLang>
            </button>
            <Modal show={showLang} onCancel={closeLang} loading={true}>
                <nav className='nav_menu_lang'>
                    <ul>
                        <UseLang es>
                            <li><div><p>Lenguaje</p><span onClick={closeLang}><i className="icon-xmark" aria-hidden="true"></i></span></div></li>
                            {url_en &&<li><Link onClick={() => handleChangeLanguaje('en')} to={`${url_en}`} >English</Link></li>}
                            {url_pt_br && <li><Link onClick={() => handleChangeLanguaje('pt-br')} to={`${url_pt_br}`}>Português</Link></li>}
                        </UseLang>
                        <UseLang en>
                            <li><div><p>Language</p><span onClick={closeLang}><i className="icon-xmark" aria-hidden="true"></i></span></div></li>
                            {url_es &&<li><Link onClick={() => handleChangeLanguaje('es')} to={`${url_es}`} >Español</Link></li>}
                            {url_pt_br && <li><Link onClick={() => handleChangeLanguaje('pt-br')} to={`${url_pt_br}`}>Português</Link></li>}
                        </UseLang>
                        <UseLang pt_br>
                            <li><div><p>Língua</p><span onClick={closeLang}><i className="icon-xmark" aria-hidden="true"></i></span></div></li>
                            {url_es &&<li><Link onClick={() => handleChangeLanguaje('es')} to={`${url_es}`} >Español</Link></li>}
                            {url_en &&<li><Link onClick={() => handleChangeLanguaje('en')} to={`${url_en}`}>English</Link></li>}
                        </UseLang>
                    </ul>
                </nav>
            </Modal>
            
        </li>
    )
}

export default LangButton
