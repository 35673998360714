import React from 'react'
import UseLang from '../../../../components/hook/UseLang'

const RoomSlot = ({ children }) => {
    return (
    <div className='ActivityRoom'>
        <UseLang es>{children}</UseLang>
        <UseLang en>{children}</UseLang>
        <UseLang pt_br>{children}</UseLang>
    </div>)
}


export default RoomSlot
