import React from 'react'

const LacnicLogoColor = props => {
    const {className, width} = props;
    return (
        <img src="/logo-lacnic.svg" alt="LACNIC" title="LACNIC" width={`${width}`} className={`logo-lacnic ${className}`} />
    )
}

export default LacnicLogoColor
