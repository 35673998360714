import { useEffect, useState } from 'react';

function useExpirationDate(deadline) {
  // Estado para almacenar si la fecha ha pasado o no
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    // Verificar si la fecha de vencimiento está definida
    if (deadline) {
      // Convertir la fecha de vencimiento a un objeto Date
      const parts = deadline.split(/[ /:]/);
      const deadlineDate = new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);

      // Obtener la fecha actual
      const fechaActual = new Date();

      // Comprobar si la fecha de vencimiento ha pasado
      const expired = fechaActual > deadlineDate;

      // Actualizar el estado con el resultado
      setIsExpired(expired);
    } else {
      // Si no hay fecha de vencimiento, establecer como no expirado
      setIsExpired(false);
    }
  }, [deadline]); // Ejecutar cuando la fecha de vencimiento cambie

  // Devolver el estado que indica si la fecha ha pasado
  return isExpired;
}

export default useExpirationDate;
