import React from 'react'
import UseLang from '../../hook/UseLang'
import './sticky-header.css'
import { Link } from 'react-router-dom'
/* import useUrlParams from '../../hook/useUrlParams';
 */
const StickyHeader = props => {
    /* const { handleParam } = useUrlParams('announcements', ['important-information-about-lacnic-39-registration']); */

    const stickyHeader = true
    return (
        <>
           { !stickyHeader ? <article className="sticky_header">
                <div>
                    <p>
                        {/* <button onClick={() => handleParam('important-information-about-lacnic-39-registration')}>
                            <UseLang es>Actividad preparatorio: ¿Qué puedo encontrar en el evento?</UseLang>
                            <UseLang en>Preparatory Activity</UseLang>
                            <UseLang pt_br>Informações importantes para se inscrever no LACNIC39</UseLang>
                        </button> */}
                        <Link to='/es/programa/actividad-preparatoria?webinar=introduccion-a-la-consola-de-linux'>
                            <UseLang es>Introducción a la Consola de Linux</UseLang>
                        </Link> 
                        <Link to='/en/programme/preparatory-activity?webinar=introduccion-a-la-consola-de-linux'> 
                            <UseLang en>Introduction to the Linux Console</UseLang>
                          </Link>
                            <Link to='/pt-br/programa/atividade-preparatoria?webinar=introduccion-a-la-consola-de-linux'>
                            <UseLang pt_br>Introdução ao Terminal do Linux</UseLang>
                            </Link>
                         

                        <span>29/04/24 - 15:00 (UTC)</span>
                    </p>
                </div>
            </article> : undefined}
        </>
    )
}

export default StickyHeader
