import React from 'react';
import { Link } from 'react-router-dom'; 
import LogoWhite from '../../design/logo/LogoWhite';
import './Logo.css'
import LogoColor from '../../design/logo/LogoColor';

function EventLogo(props) {
    const {logo_white} = props;
    return (
        <Link to="/" className='logo'>
            <figure className={`header_logo`}>
               {logo_white 
                ? 
                <LogoWhite /> 
                : 
                <LogoColor /> 
               }
            </figure>
        </Link>
    )
}  

export default EventLogo
 