import React, { useContext } from 'react';
import Modal from '../../../../components/interface/modal/Modal'; 
import Runners from './Runners';
import Social from './Social';
import './social-activities.css'
import Beer from './Beer'; 
import { SocialEventContext } from '../../../../context/DataContext';


const SocialActivities = props => {
    const { display, closeModal, socialActivity, socialSessionId } = props;

    const { handleSocialId, agendaSocialData} = useContext(SocialEventContext);
    handleSocialId(socialSessionId)  
    return (
        <Modal show={display} onCancel={closeModal} className={`socials_activities ${socialActivity === 'lacnic-runners' ? 'lacnic_runners' : '' || socialActivity === 'evento-social' ? 'socia_event' : '' || socialActivity === 'coctel-de-bienvenida' ? 'cocktail' : '' || socialActivity === 'beer-and-gear' ? 'beer-and-gear' : '' || socialActivity === 'beer-and-peer' ? 'beer-and-peer' : ''}`}>
            <div className='close_modal_social'>
                <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
            </div>
            {socialActivity === 'lacnic-runners' && <Runners data={agendaSocialData}/>}
            {socialActivity === 'coctel-de-bienvenida' ? <><Social data={agendaSocialData} /></> : undefined}
            {socialActivity === 'evento-social' ? <><Social data={agendaSocialData} /></> : undefined}
            {socialActivity === 'beer-and-gear' ? <><Beer event='gear' /></> : undefined}
            {socialActivity === 'beer-and-peer' ? <><Beer event='peer' /></> : undefined}
        </Modal>
    )
}

export default SocialActivities
