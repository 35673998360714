import React from 'react'
import UseLang from '../../../../components/hook/UseLang'
import UseHTML from '../../../../components/hook/UseHTML'

const Runners = ({ data }) => {   

  return (
    <> 
        <div className='RunnersImage'>
          <img src={data?.acf?.image} alt=""></img>
        </div>
        <div className='social_activity_content'>
          <h1>{data?.acf?.info_es?.title}</h1>
          <UseLang es>
            <UseHTML html={data?.acf?.info_es?.description} />
          </UseLang>
          <UseLang en>
            <UseHTML html={data?.acf?.info_en?.description} />
          </UseLang>
          <UseLang pt_br>
            <UseHTML html={data?.acf?.info_pt_br?.description} />
          </UseLang>
        </div> 
    </>
  )
}

export default Runners
