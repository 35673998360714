import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';     
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import Webinar from './Webinar';
import './preparatory.css' 
import settings from '../../../components/configuration/settings';

const PreparatoryActivity = props => { 
    const { handleID, handleApiURL, handleLoading, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 
    let contents = [];
    if (Array.isArray(data?.acf?.create_accordion)) {
      contents = data.acf.create_accordion.map((info, index) => ({
        acf_fc_layout: info.acf_fc_layout,
        id: info?.id,
        label: info?.label,
        content: info?.content,
        state: info?.state,
        key: index,
      }));
    }  
 
 
    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id={settings.preparatory_page_id}>
                <Header title={data?.title?.rendered} loading={loading} url_en='/en/programme/preparatory-activity' url_es='/es/programa/actividad-preparatoria' url_pt_br='/pt-br/programa/atividade-preparatoria' {...props} />
                <Main className='container' loading={loading} status={data?.status}>
                    <section className='PreparatoryActivity'>
                        <> 
                        {data ?
                            <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered} > 
                                <SponsorsSlider/> 
                                {contents.length > 0 ? (<>
                                    <Webinar contents={contents} data={data} /> 
                                </>) :  
                                null}
                                
                            </UseTimeOut>
                        : null}</>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>  
    )
}

export default PreparatoryActivity
