import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import UseHTML from '../../../components/hook/UseHTML';
import { LangContext } from '../../../context/DataContext';

function FellowshipProgramItem(props) {
  const { accordion, page_number } = props;
  const { fellowship } = useParams();
  const navigate = useNavigate();
  const { myLang } = useContext(LangContext);

  useEffect(() => {
    // Encontrar el primer elemento con estado true
    const firstPage = accordion.find(item => item.state);

    // Si hay un primer elemento con estado true, obtener su slug
    const firstPageSlug = firstPage ? slugify(firstPage.label) : '';

    // Construir la URL de redirección
    const redirectUrlEs = `/es/como-participar/programa-de-becas-de-lacnic/${firstPageSlug}`;
    const redirectUrlEn = `/en/how-to-participate/lacnic-fellowship-program/${firstPageSlug}`;
    const redirectUrlPtBr = `/pt-br/como-participar/programa-de-bolsas-do-lacnic/${firstPageSlug}`;

    // Redireccionar solo si no hay un parámetro fellowship en la URL actual
    if (!fellowship) {
      if(myLang === 'es'){
        navigate(redirectUrlEs);
      }
      if(myLang === 'en'){
        navigate(redirectUrlEn);
      }
      if(myLang === 'pt-br'){
        navigate(redirectUrlPtBr);
      }
      
    }
  }, [accordion, fellowship, navigate, myLang]);

  return (
    <>
      {accordion?.map((item) => {
        const shouldRender =
          (fellowship === slugify(item.label) && item.id === `${page_number}`) ||
          (fellowship === undefined && item.id === '1');

        if (shouldRender) {
          return (
            <div key={item.id}>
              <h2>
                <em>{item.label}</em>
              </h2>
              <UseHTML html={item.content} />
            </div>
          );
        }

        return null;
      })}
    </>
  );
}

export default FellowshipProgramItem;
