
import React from 'react'
import useUrlParams from '../../../components/hook/useUrlParams'
import Modal from '../../../components/interface/modal/Modal'
import JoinActivity from './JoinActivity';
import AccessCode from './AccessCode';
import UrlAndCode from '../../../components/interface/url-and-code/UrlAndCode';
import UseHTML from '../../../components/hook/UseHTML';


const WebinarModal = (props) => {
    const { contents, consoleLinuxSlug } = props;
    const { paramValue, display, /* handleParam,  */closeModal } = useUrlParams('webinar', ['propuestas-de-politicas-fpp', 'que-puedo-encontrar-en-el-evento', `${consoleLinuxSlug}`]);
    
    return (
        <Modal onCancel={closeModal} show={display} paramValue='webinar' className='preparatory_activity'>
            <div className='close_preparatory_activity'>
                <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true" ></i></button>
            </div>

            <div className='preparatory_activity_modal'>
                {paramValue === 'propuestas-de-politicas-fpp' || paramValue === 'que-puedo-encontrar-en-el-evento' ?
                    <>{contents[1] ?
                        <div className='' id='que-puedo-encontrar-en-el-evento'>
                            <div>

                                <h2 className='preparatory_title'>{contents[0]?.label}</h2>
                                <UseHTML className="preparatory_content" html={contents[0]?.content} />
                                <h2 className='preparatory_title'>{contents[1]?.label}</h2>
                                <UseHTML className="preparatory_content" html={contents[1]?.content} />
                                {
                                    contents[1]?.state ? <>
                                        <JoinActivity />
                                        {/* false para no mostrar; true cuando hay código de acceso */}
                                        <AccessCode code={false} />
                                        <UrlAndCode url_text='https://lacnic-net.zoom.us/j/89521282748' url='https://lacnic-net.zoom.us/j/89521282748?pwd=UFlDNCt3NEVCaTIrNldndVlkbUdqZz09' code='none' />
                                    </>
                                        : null}
                            </div>
                        </div> : null
                    }</>
                    : undefined
                }  
                {paramValue === `${consoleLinuxSlug}` ? 
                    <>{contents[2] ?
                        <div className='' id={`${consoleLinuxSlug}`}>
                            <div>

                                <h2 className='preparatory_title'>{contents[2]?.label}</h2> 
                                <UseHTML className="preparatory_content" html={contents[2]?.content} /> 
                                {
                                    contents[2]?.state ? <>
                                        <JoinActivity />
                                        {/* false para no mostrar; true cuando hay código de acceso */}
                                        <AccessCode code={false} />
                                        <UrlAndCode url_text='https://lacnic-net.zoom.us/j/89747675452' url='https://lacnic-net.zoom.us/j/89747675452?pwd=RkozdFU1eVo0c3NWQW1kN2JKUzRkdz09#success' code='none' />
                                    </>
                                        : null}
                            </div>
                        </div> : null
                    }</>
                : null}

            </div>
        </Modal>

    )
}

export default WebinarModal

