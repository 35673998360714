import React, { useContext,  useEffect,  useState } from 'react';
import { LangContext, PostContext } from './../../../context/DataContext'
import Main from './../../../components/interface/main/Main';
import Footer from './../../../components/navegation/footer/Footer';
import Header from './../../../components/navegation/header/Header'; 
import UsePost from './../../../components/hook/UsePost';
import OrganizersElement from './../../../components/design/organizers/OrganizersElement';
import UseTimeOut from './../../../components/hook/UseTimeOut'; 
import UseLangLocation from './../../../components/hook/UseLangLocation'; 
import SponsorsSlider from './../../../components/sections/sponsors/SponsorsSlider';
import SponsorshipHeader from './SponsorshipHeader';
import './sponsorship.css'
import SponsorshipTable from './SponsorshipTable';
import SponsorshipContact from './SponsorshipContact';
import WhySponsor from './WhySponsor';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import { useNavigate } from 'react-router-dom';  
import settings from '../../../components/configuration/settings';

const Sponsorship = props => {
  const {myLang} = useContext(LangContext)
  const { url } = props; 

    const { handleApiURL, handleID,  handleLoading,  data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 
    let contents = [];
    if (Array.isArray(data?.acf?.create_accordion)) {
      contents = data?.acf?.create_accordion?.map((info, index) => ({
        acf_fc_layout: info?.acf_fc_layout,
        id: info?.id,
        label: info?.label,
        content: info?.content,
        key: index,
      }));
    }
    const navigate = useNavigate();
    useEffect(()=>{
        navigate(`/${myLang}/${url}`)
    },[navigate, myLang, url])
     
    return (
      <>
       
        {data ? (
          <UseLangLocation>
            <UsePost
              enabled
              handleApiURL={handleApiURL}
              url="https://apievt.lacnic.net/wp-json/data/sponsorship"
              handleLoading={handleLoading}
              handleID={handleID}
              id={settings.sponsorship_page_id}
            >
              <Header title={data?.title?.rendered} url_es="/es/patrocinios" url_pt_br="/pt-br/patrocinios" url_en="/en/sponsorship" loading={loading} {...props} />
              <Main className="container" status={data?.status} loading={loading}>
                <section>
                  <UseTimeOut timeout="100" loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                    {data ? (
                      <>
                        <SponsorsSlider />
                        <StickyHeader />
                        <SponsorshipHeader title={data?.title?.rendered} content={data?.content?.rendered} />
                        {contents.length > 0 ? (
                          <>
                            <WhySponsor contents={contents} />
                            <SponsorshipContact contents={contents} /> 
                            <SponsorshipTable contents={contents} />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </UseTimeOut>
                </section>
              </Main>
              <OrganizersElement />
              <Footer className="master_foot foot_home" />
            </UsePost>
          </UseLangLocation>
        ) : null}
      </>
    );
  };
  
  export default Sponsorship;
 