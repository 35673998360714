import React from 'react'
import UseLang from '../../../../../components/hook/UseLang'
import './registration-panel.css'
import config from '../../../../../components/configuration/config'
import UseHTML from '../../../../../components/hook/UseHTML'

function RegistrationPanel({ data }) {
    return (
        <> {data?.registry_url ? <div className='registration-panel'>
            <p>
                <UseLang es>
                    <strong>Importante:</strong> <em>{data?.info_es?.title}</em> tiene su <RegistrationLink data={data}>registro particular aquí</RegistrationLink>. Para participar de esta actividad es necesario registrarse también a <RegistrationEvent>aquí</RegistrationEvent>.
                    <UseHTML html={`${data?.info_es.description}`} />
                </UseLang>
                <UseLang en>
                    <strong>Important:</strong> <em>{data?.info_en?.title}</em> has its own <RegistrationLink data={data}>registration here.</RegistrationLink> To participate in this activity, you must also register for  <RegistrationEvent>here</RegistrationEvent>
                    <UseHTML html={`${data?.info_en.description}`} /> 
                </UseLang>
                <UseLang pt_br>
                    <strong>Importante:</strong> <em>{data?.info_pt_br?.title}</em> e tem um <RegistrationLink data={data}>registro particular aqui</RegistrationLink>. Para participar desta atividade, é necessário se inscrever também no <RegistrationEvent>aquí</RegistrationEvent>.
                    <UseHTML html={`${data?.info_pt_br.description}`} /> 
                </UseLang>
            
            </p>
        </div> : null}</>
    )
}

export default RegistrationPanel

const RegistrationLink = props => {
    const { children, data } = props;
    return (
        <a className='registration_button' href={`${data?.registry_url}`} target='_blank' rel="noreferrer">
            {children}
        </a>
    )
}
const RegistrationEvent = ({ children }) => {
    return (
        <a href={`${config.registrationURL}`} target='_blank' rel="noreferrer">
            {config.currentEvent} {children}
        </a>
    )
}