import React from 'react';
import './welcome.css';
import WelcomeContent from './WelcomeContent';

const Welcome = props => {
     

    return (
        <> 
            <WelcomeContent {...props} />
        </>
    )
}

export default Welcome
