import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import FellowshipProgramHeader from './FellowshipProgramHeader';
import FellowshipProgramContent from './FellowshipProgramContent';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader'; 
import settings from '../../../components/configuration/settings'; 



const FellowshipProgramBody = props => {
    const { page_number } = props;
    const { handleApiURL, handleID,  handleLoading, data } = useContext(PostContext)
    const [loading, setLoading] = useState(true);  
    return (
        <>{data ?<UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id={settings.becas_page_id} >
                <Header title={data?.title?.rendered}  loading={loading} {...props} />
                <Main className='container fellowship_program' status={data?.status}   loading={loading} >
                    <section>
                        <UseTimeOut timeout='300' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <FellowshipProgramHeader content={data?.content?.rendered} title={data?.title?.rendered} button={data?.acf?.buttons[0]}  members={false} />
                            <FellowshipProgramContent accordion={data?.acf?.create_accordion} page_number={page_number} members={false} {...props}/>
                         </UseTimeOut> 
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> : undefined}</>
    )
}

export default FellowshipProgramBody

