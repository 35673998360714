import React, { useContext } from 'react';
import { TimeZoneContext } from '../../../../context/DataContext';
import config from '../../../../components/configuration/config';
const { format: formatTz } = require("date-fns-tz");
 

const HourRow = props => {
    const { time, item } = props;
    if (item === 'time') {
        return (
            <UtcTime time={time} />
        )
    }
}
 

export default HourRow

const UtcTime = props => {
 
    
    const { time } = props; 
    const { myTimeZone, gmt } = useContext(TimeZoneContext)


    const localtime = `${myTimeZone === config.eventTimeZone ? `${time}` : `${time} ${config.utcEventTimeZone}`}`

    const dateLocalTime = new Date(localtime);
    const utcTime =
        `${myTimeZone === config.eventTimeZone ? `${time} ${gmt}` : `${time}`
        }`

    const dateUtcTime = new Date(utcTime);

    const showTime = ` 
        ${myTimeZone === 'localtime' ? `${formatTz(dateUtcTime, 'HH:mm')}` : `${formatTz(dateLocalTime, 'HH:mm')}`}
        
    `;

    return (
        <>
            {showTime}
        </>
    )
}




