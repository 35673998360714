import React, { useState, useContext, useEffect } from 'react'
import { PostContext, SessionContext } from '../../../../context/DataContext'
import UsePost from '../../../../components/hook/UsePost'
import Main from '../../../../components/interface/main/Main'
import Footer from '../../../../components/navegation/footer/Footer'
import Header from '../../../../components/navegation/header/Header'
import TimeCheckbox from '../table-functionalities/TimeCheckbox'
import Timetable from '../timetable/Timetable'
import AgendaHeader from './AgendaHeader'
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement'
import UseTimeOut from '../../../../components/hook/UseTimeOut'
import './agenda-body.css'
import UseLangLocation from '../../../../components/hook/UseLangLocation'
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader'
import DevelopingTheAgenda from '../agenda-sessions/developing-agenda/DevelopingTheAgenda'
import DayAgendaPicker from '../table-functionalities/DayAgendaPicker'
import config from '../../../../components/configuration/config'

const AgendaBody = props => {
    const { handleApiURL, handleID, handleLoading, data } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
    const { myEventAgenda } = useContext(SessionContext);

    const [hasEvent, setHasEvent] = useState(null)

    useEffect(() => {
        // Verificar si our_events está definido y es un array antes de usar includes
        // Desde la API de WordPress https://apievt.lacnic.net/wp-admin/post.php?post=670&action=edit&lang=es marcar el evento actual para mostrar la agenda 
        if (Array.isArray(data?.our_events)) {
            //ID del evento actual en config
            const isEvent = data?.our_events.includes(config.currentEventID);
            setHasEvent(isEvent);
        } else {
            console.error("our_events no es un array");
        }
    }, [data]);

    return (
        <UseLangLocation> 
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id='670'>
                <Header title={data?.title?.rendered} loading={loading} {...props} />
                <Main className='container schedule_table' status={data?.status} loading={loading}  >
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}>
                            {hasEvent === false ?
                                <>
                                    <DevelopingTheAgenda agenda_state={false} />
                                    <SponsorsSlider />
                                    <StickyHeader />
                                    <AgendaHeader title={data?.title?.rendered} />
                                    <TimeCheckbox />
                                    <DayAgendaPicker agendaData={myEventAgenda} />
                                    <Timetable {...props} /></> :
                                <section>
                                    <article className='agenda_header'>
                                        <div>
                                            <h1>Agenda</h1>
                                            <DevelopingTheAgenda agenda_state={false} />
                                        </div>
                                    </article>
                                </section>}
                        </UseTimeOut>
                    </section>
                </Main>
            </UsePost>
            <OrganizersElement />
            <Footer className="master_foot foot_home" />
        </UseLangLocation>
    )
}

export default AgendaBody
