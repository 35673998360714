import React, { useContext, useState } from 'react'
import { PostContext } from '../../../context/DataContext';
import './homepage.css'
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Welcome from '../welcome/Welcome';
import Main from '../../../components/interface/main/Main'; 
import UseTimeOut from '../../../components/hook/UseTimeOut';
import FrontPage from '../frontpage/FrontPage';
import UseLangLocation from '../../../components/hook/UseLangLocation'; 
import Popup from '../../../components/interface/popup/Popup';
import PopupFtl from '../popup/popupFtl'; 
import settings from '../../../components/configuration/settings';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';


const HomePageContents = props => {
    const { handleID, handleApiURL, handleLoading, data } = useContext(PostContext);  
    const [loading, setLoading] = useState(true); 
    const homepage = data?.acf?.homepage; 
    return (
        <UseLangLocation>
                <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/homepage' handleLoading={handleLoading} handleID={handleID} id={settings.home_page_id}>
            
                <Header title={data?.title?.rendered} loading={loading} url_en={'/en'} url_es={'/es'} url_pt_br={'/pt-br'} homePage={2} logo_white={true} {...props} />
                <Main className={`content_home`} loading={loading} status={data?.status}>
                    <section>
                        <UseTimeOut timeout='200' loading={loading} setLoading={setLoading} title={data?.title?.rendered} homepage>
                            <FrontPage featured_links={homepage?.featured_links} featured_content={homepage?.header} featured_image={homepage?.featured_image} featured_logo={homepage?.featured_logo}  />
                            <Welcome about_event={homepage?.about_event}  organizers={homepage?.organizers}  />
                            <SponsorsSlider /> 
                            <Popup show={false}>
                                <PopupFtl />
                            </Popup>
                            {/* Nota: Agregar registro virtual, networking, agenda, transmisión en vivo, */}
                        </UseTimeOut>
                    </section>
                </Main>
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}
export default HomePageContents;

