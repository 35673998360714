import React from 'react'
import UseLang from '../../hook/UseLang'

function CodeOfConduct() {
    return (
        <div className='code-of-conduct'>
            <div>
                <UseLang es>
                <p>El evento estará sujeto a los estándares de comportamiento establecidos en el Código de Conducta de la Comunidad de LACNIC. Puedes leerlo <a href="https://www.lacnic.net/4038/1/lacnic/codigo-de-conducta-de-la-comunidad-de-lacnic" target='_blank' rel="noreferrer">aquí</a>.</p>
                </UseLang>
                <UseLang en>
                    <p>The standards of behavior defined in the LACNIC Community Code of Conduct will apply to this event. <a href="https://www.lacnic.net/4039/2/lacnic/lacnic-community-code-of-conduct" target='_blank' rel="noreferrer" >Click here</a> to read the Code of Conduct.</p>
                </UseLang>
                <UseLang pt_br>
                <p>O evento estará sujeito aos padrões comportamentais estabelecidos no Código de Conduta da Comunidade do LACNIC. <a href="https://www.lacnic.net/4040/3/lacnic/codigo-de-conduta-da-comunidade-do-lacnic" target='_blank' rel="noreferrer">Leia aqui o Código</a>.</p>
                </UseLang>
            </div>
        </div>
    )
}

export default CodeOfConduct
