import React, { useContext,  useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import FtlAccordion from './FtlAccordion';
import FtlContent from './FtlContent';
import FtlButton from './FtlButton'  
import UsePost from '../../../components/hook/UsePost';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation';
import './ftl.css'
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader'; 
import settings from '../../../components/configuration/settings';

const Ftl = props => { 
    const {  handleApiURL, handleID, handleLoading, data } = useContext(PostContext)
    const [loading, setLoading] = useState(true);

    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id={settings.ftl_page_id} >
                <Header title={data?.title?.rendered} url_es='/es/como-participar/ftl' url_pt_br='/pt-br/como-participar/ftl' url_en='/en/how-to-participate/ftl' loading={loading} {...props} />
                <Main className='container' status={data?.status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={data?.title?.rendered}> 
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <FtlContent title={data?.title?.rendered} content={data?.content?.rendered} />
                            <FtlAccordion title={data?.title?.rendered} accordion={data?.acf?.create_accordion} />
                            <FtlButton button={data?.acf?.buttons[0]} /> 
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default Ftl
