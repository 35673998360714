// config.js
const config = {
    currentEvent: "LACNIC 42",
    pastEvent: "LACNIC 41", 
    lacnogEvent: "LACNOG 2024",
    eventDate: "2024/10/07",
    // ID del evento actual: https://apievt.lacnic.net/wp-admin/edit-tags.php?taxonomy=our_events&post_type=agenda
    currentEventID: 204, 
    currentEventID_en: 205, 
    currentEventID_pt_br: 206,
    // ID del evento pasada
    pastEventID: 179,
    pastEventID_en: 180,
    pastEventID_pt_br: 181,
    // Archivos de configuración Zona Horaria: HourRow.jsx, TimeZoneState.jsx
    eventTimeZone: 'America/Asuncion',
    utcEventTimeZone: 'GMT-0300',
    currentEventCountry: 'Paraguay',
    // URLs importantes
    registrationURL: 'https://eventos.lacnic.net/ev4/detail?id=lacnic-41',
  };
  
export default config;
  